import React from "react"
import { graphql } from "gatsby"

import Inspiration from "./inspiration"

const Main = ({ data }) => <Inspiration data={data} />
export default Main

export const query = graphql`
  query inspiration($id: String!) {
    post: wpInspiration(id: { eq: $id }) {
      title
      content
      date
      slug
      featuredImage {
        node {
          localFile {
            ...HeroImage
          }
        }
      }
      tags {
        nodes {
          databaseId
        }
      }
      acfInspiration {
        authorship {
          name
          role
        }
        recipeslist {
          ... on WpPost {
            title
            slug
            featuredImage {
              node {
                altText
                srcSet
              }
            }
            acfRecipe {
              difficulty
              cookingtime {
                hours
                minutes
              }
              maintag {
                name
              }
            }
          }
        }
      }
    }
  }
`
